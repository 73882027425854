// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "node_modules/@ngneat/hot-toast/src/styles/styles.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";

// Plus imports for other components in your app.
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/angular2-multiselect-dropdown/themes/default.theme.scss";
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Seller-Portal-primary: mat.define-palette(mat.$indigo-palette);
$Seller-Portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Seller-Portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Seller-Portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $Seller-Portal-primary,
      accent: $Seller-Portal-accent,
      warn: $Seller-Portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($Seller-Portal-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: poppins-regular-400;
  src: url(../src/assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: poppins-medium-500;
  src: url(../src/assets/fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: poppins-semibold-600;
  src: url(../src/assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: poppins-bold-700;
  src: url(../src/assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppins-extrabold-800;
  src: url(../src/assets/fonts/Poppins/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: poppins-dark-900;
  src: url(../src/assets/fonts/Poppins/Poppins-Black.ttf);
}

@font-face {
  font-family: "inter-bold"; /* Replace with the name of your font */
  src: url(../src/assets/fonts/Inter/Inter-bold.ttf);
  // font-weight: 400; /* Normal weight */
  // font-style: normal; /* Normal style */
}

$primary-color: #169839;
$secondary-color: #ebf5ee;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-drawer-inner-container {
  overflow: hidden !important;
}

.hot-toast {
  // position: absolute;
  min-width: 250px !important;
  margin-top: 30vh !important;
  // margin-left: 200px !important;

  .hot-toast-icon {
    align-self: unset !important;
    padding: unset !important;
    margin-left: 16px;

    .hot-toast-loader-icon {
      width: 24px;
      height: 24px;
    }
  }

  // .hot-toast-message {
  //   font-size: 20px;
  // }
}

//styles for checkbox, radio buttons and multiselect dropdowns

.selected-list .c-list .c-token {
  background: #ebf5ee !important;
}

.selected-list .c-list .c-token .c-label {
  color: #000 !important;
}

.c-remove svg {
  fill: #000 !important;
}
.selected-item {
  background: #f9f9f9;
}

.c-btn {
  min-height: 40px !important;
  border: 1px solid #c6c6c6 !important;
}

.c-btn.disabled {
  background-color: #f6f5f5 !important;
  cursor: not-allowed !important;
}

.c-btn:focus-visible,
.c-btn:active,
.c-btn:focus-within {
  border: 1px solid #747474 !important;
}

.form-check-input {
  border: 1px solid #622415;
  height: 18px;
  width: 18px;
  -webkit-tap-highlight-color: transparent;
}
.form-check-input[type="checkbox"] {
  border-radius: 1px !important;
}

.form-check-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #622415;
}

.form-check-input:checked {
  background-color: #622415 !important;
  color: #592202 !important;
}

.pure-checkbox input[type="checkbox"]:checked + label:before {
  background: #592202 !important;
}

.pure-checkbox input[type="checkbox"] + label:before {
  color: #592202;
  border: 1px solid #592202;
}

.grecaptcha-badge {
  visibility: hidden;
}

.snackbar-styles {
  text-align: center;
  margin-bottom: 100px !important;
  background-color: #169839;
  color: #fff;
}

.ng-select ::placeholder {
  font-size: 12px !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
  .ng-placeholder {
    color: #000 !important;
    font-size: 12px;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #ebf5ee !important;
  color: #000;
  font-size: 12px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #000;
  background-color: #ebf5ee !important;
}

.ng-option,
.ng-select div,
.ng-select input,
.ng-select span {
  font-size: 12px;
}

/* */

span.info,
label.info {
  padding: 5px 0px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  // max-width: fit-content;
  display: flex;
  align-items: center;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.red-asterick {
  color: red;
  margin-left: 2px;
}

span.info {
  color: #8e8e8e;
}

label.info {
  color: black;
}

.section {
  padding-bottom: 15px;

  .section-header {
    margin-bottom: 16px;

    .label {
      font-family: Helvetica;
      font-size: 15px;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    .info {
      // background-color: #E7F3FF;
      color: #8e8e8e;
      padding: 3px 0px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px;
      max-width: fit-content;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }
  }
}

.info-text {
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #979797;
  padding: 0 5px;
}

.form-input-error-text {
  color: #f04438;
  font-size: 12px;
  padding-top: 3px;
  display: inline-block;
  line-height: 18px;
}

form.ng-submitted {
  input.ng-invalid,
  textarea.ng-invalid,
  input[type="checkbox"].ng-invalid,
  ng-select.ng-invalid,
  .ng-invalid-image-upload,
  .form-check-input.ng-invalid {
    border: 1px solid #f04438 !important;
  }

  ng-select.ng-invalid {
    border-radius: 8px;
  }

  .form-input-error-text {
    color: #f04438;
    font-size: 13px;
    padding-top: 3px;
  }

  input.drafted.ng-invalid,
  div.drafted.ng-invalid-image-upload,
  ng-select.drafted.ng-invalid {
    border: 1px solid #c6c6c6 !important;
  }
  .form-check-input.drafted.ng-invalid {
    border: 1px solid #622415 !important;
  }
}

input:disabled {
  cursor: not-allowed;
}

form .form-btn-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  z-index: 9999;
  box-shadow: 0px -4px 8px 0px #0000000f;
  background: white;
}

.home-page-snackbar-styles {
  text-align: center;
  margin-bottom: 100px !important;
  background-color: #ffffff;
  color: #000000;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .section {
    // padding-bottom: 10px;

    .section-header {
      margin-bottom: 10px;

      .label {
        font-family: Helvetica;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .info {
        // background-color: #E7F3FF;
        color: #8e8e8e;
        padding: 3px 0px;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: 500;
        border-radius: 4px;
        max-width: fit-content;
        display: flex;
        align-items: center;

        img {
          margin-right: 5px;
        }
      }
    }
  }
}

.button-green {
  background-color: $primary-color;
  color: #fff;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ng-select .ng-select-container {
  border-radius: 8px !important;
  min-height: 40px !important;
}

.dropdown-menu {
  overflow: auto;
  max-height: 350px;
  padding-bottom: 0;
}

.dropdown-item {
  white-space: normal;
}
.mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}
.mat-mdc-form-field-flex {
  height: 20px;
}
.mdc-text-field {
  padding: 0px !important;
}
.mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-bottom-style: none !important;
}
.mat-calendar-body-selected {
  background-color: #169839;
}
.mat-calendar-body-in-range::before {
  background: #ebf5ee;
}
@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #ebf5ee;
    border-color: #169839;
  }
}
.mat-mdc-icon-button svg {
  path {
    fill: #212529 !important;
  }
}
